<template>
    <ActionPage />
</template>

<script>
import {
    mapActions
} from 'vuex'

import ActionPage from '../components/ActionPage.vue'

export default {
    data: () => ({
    }),
    components: {
        ActionPage
    },
    methods: {
        ...mapActions(['getUserInfo'])
    },
    mounted() {
    }
}
</script>

